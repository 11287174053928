<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<!-- KeywordList -->
		<KeywordsList></KeywordsList>

		<!-- Source List -->
		<SourceList></SourceList>

		<!-- Show finish create source list  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			current_step="app.create_source_list.title"
			:dialog="dialog"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import { mapState } from "vuex";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import SourceList from "@/components/research/modules/gatherSources/source/List.vue";
import KeywordsList from "@/components/research/modules/gatherSources/source/KeywordsList.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { validateCreateSource } from "@/utils/helpers/source";

export default {
	name: "CreateSourceListPage",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		StageIntroDialog,
		SourceList,
		StageFinishDialog,
		KeywordsList,
	},

	computed: {
		...mapState({
			source_list_length: (state) => state.source.list.length,
		}),
	},

	created() {
		this.$eventBus.$on("finish-create-source-list", (payload) => {
			this.validateAndProceed();
		});
	},

	methods: {
		validateAndProceed() {
			try {
				// First, validate the stage
				validateCreateSource();

				// Then, open the finish dialog
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-create-source-list");
	},
};
</script>
