var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"680"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","rounded":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icons.values.plus")]),_vm._v(" "+_vm._s(_vm.$t("app.add_keyword"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AppDialog',{attrs:{"heading":{
			label: 'app.add_keyword',
		},"action":{
			label: 'app.create',
			event: 'create',
		}},on:{"close":function($event){_vm.dialog = false},"create":function($event){return _vm.keywordCreate()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t("app.create_new_keyword_and_add_to_list")))]),_c('v-form',{ref:"keyword_create_form",staticClass:"mt-8"},[_c('v-text-field',{attrs:{"label":_vm.$t('app.placeholder.type_keyword'),"dense":"","outlined":"","rules":[_vm.rules.required],"color":"greenAccent2","background-color":"white"},model:{value:(_vm.keyword_name),callback:function ($$v) {_vm.keyword_name=$$v},expression:"keyword_name"}})],1)]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }