<template>
	<!-- Create a keyword Dialog -->
	<v-dialog persistent v-model="dialog" width="680">
		<!-- Dialog Activator -->
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="success" rounded v-bind="attrs" v-on="on" depressed>
				<v-icon left>$vuetify.icons.values.plus</v-icon>
				{{ $t("app.add_keyword") }}
			</v-btn>
		</template>

		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.add_keyword',
			}"
			:action="{
				label: 'app.create',
				event: 'create',
			}"
			@close="dialog = false"
			@create="keywordCreate()"
		>
			<template v-slot:dialog-content>
				<div>{{ $t("app.create_new_keyword_and_add_to_list") }}</div>

				<!-- Form to create keyword -->
				<v-form ref="keyword_create_form" class="mt-8">
					<v-text-field
						:label="$t('app.placeholder.type_keyword')"
						dense
						v-model="keyword_name"
						outlined
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
					></v-text-field>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "KeywordAddDialog",

	props: {
		fav_mark: {
			default: false,
			type: Boolean,
		},
	},

	data() {
		return {
			loading: false,
			dialog: false,
			keyword_name: "",
			rules,
		};
	},

	components: {
		AppDialog,
	},

	methods: {
		async keywordCreate() {
			try {
				this.loading = true;

				if (!this.$refs.keyword_create_form.validate()) {
					return;
				}

				// Create keyword
				await this.$store.dispatch("keyword/create", {
					name: this.$xss.sanitize(this.keyword_name),
					selected: this.fav_mark,
				});

				// Set list after creating
				await this.$store.dispatch("keyword/favlist");

				this.dialog = false;

				//reset keyword name
				this.keyword_name = "";
			} catch (error) {
				this.$announce.error(error);
				this.dialog = false;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
