<template>
	<v-expansion-panels
		v-if="fav_keyword_list && fav_keyword_list.length"
		:value="0"
	>
		<v-expansion-panel>
			<v-expansion-panel-header
				:expandIcon="$vuetify.icons.values.arrow_up"
				class="font-weight-black subtitle-1"
			>
				{{ $t("app.keyword_list") }}
				<div class="d-inline-block ms-10">
					<KeywordAddDialog :fav_mark="true" />
				</div>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<v-row no-gutters>
					<v-col sm="2" v-for="keyword in fav_keyword_list" :key="keyword.id">
						<v-row no-gutters align="center">
							<v-checkbox
								on-icon="$vuetify.icons.values.check_outline"
								rounded
								color="wr_blue_1"
								hide-details
								class="mb-4"
								:input-value="keyword.completed"
								:true-value="true"
								:false-value="false"
								@click="toggleCompleted(keyword)"
							></v-checkbox>
							<span
								:class="keyword.completed ? 'text-decoration-line-through' : ''"
								>{{ keyword.name }}</span
							>
						</v-row>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import KeywordAddDialog from "@/components/research/modules/gatherSources/KeywordAddDialog.vue";

export default {
	name: "SourceKeywordsList",

	components: {
		KeywordAddDialog,
	},

	computed: {
		...mapState({
			fav_keyword_list: (state) => state.keyword.fav_list,
		}),
	},

	mounted() {
		this.getFavList();
	},

	methods: {
		async getFavList() {
			try {
				await this.$store.dispatch("keyword/favlist");
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async toggleCompleted(keyword) {
			try {
				this.$loader.start();

				await this.$store.dispatch("keyword/toggleCompleted", {
					keywordId: keyword.id,
					completed: !keyword.completed,
				});

				this.getFavList();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
>>> .v-expansion-panel-header__icon {
	margin: 0 !important;
}

>>> .v-expansion-panel-header__icon .v-icon {
	font-size: 2.25rem !important; /** 36px */
	color: var(--v-wr_blue_1-base) !important;
}
</style>
